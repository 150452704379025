import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import Estilos from '../components/mainContent/container.module.css'
import Imagen500esemiautomaticabandejamanual from '../components/imageComponents/imagen5000Esemiautomaticabandejamanual'

const IndexPage = () => (
  <Layout>
    <SEO title='5000 E SEMIAUTOMÁTICA' />
    <Header siteTitle='OR-PRINTER Semiautomática con bandeja manual 5010' />




    <Container>
      <div className='row justify-content-md-center'>
        <div className='col-12 col-md-6 text-center'>
            <Imagen500esemiautomaticabandejamanual  />
          
        </div>
        <div className='col-12 col-md-6 conmargen'>
      <h3 className={Estilos.username}>Especificaciones Técnicas:</h3>
            <ul className='aplicaciones'>
                  <li>Máquina semiautomática accionada por motor eléctrico.</li>
                  <li>Sistemas de trabajo a bandeja manual o pedal.</li>
                  <li>Alimentación eléctrica: 230 V.</li>
                  <li>Poténcia eléctrica máxima: 750 W.</li>
                  <li>Presión Máxima: 1300 kgs.</li>
                  <li>Altura máxima de la pieza: 65 mm.</li>
                  <li>Área máxima de impresión: 200 X 100 mm.</li>
                  <li>Cadencia de trabajo de hasta 1100 impresiones / hora.</li>
                  <li>Regulador de temperatura digital P.I.D. hasta 300&deg; C. </li>
                  <li>Avance de película por motor eléctrico controlado por temporizador electrónico.</li>
                  <li>Cabezal autobasculante, regulable en altura e inclinación.</li>
                  <li>Contador digital progresivo, regresivo y totalizador.</li>
                  <li>Peso: 62 Kg.</li>
                  <li>Dimensiones: Alto: 75 cms. Ancho: 50 cms. Fondo: 60 cms.</li>
                </ul>

        </div>
      </div>
    </Container>



  </Layout>
)

export default IndexPage
